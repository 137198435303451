import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function TextField(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${
              theme.palette.mode === 'dark'
                ? theme.palette.grey[800]
                : theme.palette.common.white
            } inset`
          }
        }
      }
    }
  };
}
