import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { PaymentState } from '../../@types/payment';

// ----------------------------------------------------------------------

const initialState: PaymentState = {
  isLoading: false,
  error: false,
  plan: undefined
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setPlanSuccess(state, action: { payload: { id: number; value: string } }) {
      state.plan = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

export function setPlan(valueID: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setPlanSuccess({ id: valueID, value: 'test' }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
