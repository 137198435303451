import { useDispatch, useSelector } from 'react-redux';

// redux
import { RootState } from '../redux/store';
import { login, register, logout, update } from '../redux/slices/authJwt';
import { User } from '../@types/user';

type LoginParams = {
  email: string;
  password: string;
};

type RegisterParams = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export default function useAuth() {
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state: RootState) => state.authJwt
  );

  // JWT Auth
  return {
    method: 'jwt',
    user,
    isLoading,
    isAuthenticated,

    login: async ({ email, password }: LoginParams) =>
      dispatch(
        login({
          email,
          password
        })
      ),

    register: async ({
      email,
      password,
      firstName,
      lastName
    }: RegisterParams) =>
      dispatch(
        register({
          email,
          password,
          firstName,
          lastName
        })
      ),

    logout: () => dispatch(logout()),

    resetPassword: async (email: string) => email,

    updateProfile: async (data: User) => {
      await Promise.all([dispatch(update({ data }))]);
    }
  };
}
