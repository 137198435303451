import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Drawer, Hidden, Link, Typography } from '@material-ui/core';
// types
import sidebarConfig from './SidebarConfig';
import Sidebar from '../../components/sidebar/Sidebar';
import MyAvatar from '../../components/MyAvatar';
import useAuth from '../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 5),
  borderRadius: theme.shape.borderRadiusSm,
  boxShadow: theme.palette.mode === 'light' ? theme.shadows[24] : 'none',
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.common.white
      : theme.palette.grey[900]
}));

type NavBarProps = {
  isOpenSidebar?: boolean;
  onCloseSidebar?: VoidFunction;
};

function SidebarNav() {
  const { user } = useAuth();

  return (
    <>
      <Box sx={{ px: 2.5, py: 3 }} />

      <Link
        underline="none"
        component={RouterLink}
        to={PATH_DASHBOARD.user.root}
      >
        <AccountStyle>
          <MyAvatar />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {user.profile.firstName} {user.profile.lastName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {user.role.name}
            </Typography>
          </Box>
        </AccountStyle>
      </Link>
      <Sidebar items={sidebarConfig()} />
    </>
  );
}

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar
}: NavBarProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          <SidebarNav />
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          <SidebarNav />
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
