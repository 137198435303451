// types
import { Coupon, CouponFilters } from '../../@types/coupon';
// components
import axios from '../../utils/axios';

export async function get(filters?: CouponFilters) {
  const { data } = await axios.get<Coupon[]>('coupon', { params: filters });

  return data;
}
