// routes
import { PATH_DASHBOARD, PATH_ADMIN } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import { PERMISSIONS } from '../../routes/permissions';
import { Props } from '../../@types/Sidebar';
import { translate } from '../../utils/translate';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  member: getIcon('ic_member'),
  adminDashboard: getIcon('ic_components'),
  plan: getIcon('ic_copy'),
  user: getIcon('ic_user'),
  permission: getIcon('ic_authenticator')
};

const sidebarConfig = (): Array<Props> => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: translate('general.general'),
    items: [
      {
        title: translate('general.dashboard'),
        permissions: PERMISSIONS.dashboard.root,
        href: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: translate('general.financial'),
        permissions: PERMISSIONS.admin.default,
        href: PATH_ADMIN.general.app,
        icon: ICONS.dashboard
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------

  {
    subheader: translate('general.management'),
    items: [
      {
        title: translate('general.members'),
        permissions: PERMISSIONS.member.default,
        href: PATH_DASHBOARD.member.cards,
        icon: ICONS.member
      },
      {
        title: translate('general.users'),
        permissions: PERMISSIONS.member.default,
        href: PATH_DASHBOARD.user.cards,
        icon: ICONS.user
      },
      {
        title: translate('general.plans'),
        permissions: PERMISSIONS.member.default,
        href: PATH_DASHBOARD.plan.root,
        icon: ICONS.plan
      },
      {
        title: translate('general.permissions'),
        permissions: PERMISSIONS.member.default,
        href: PATH_DASHBOARD.permission.root,
        icon: ICONS.permission
      }
    ]
  }
];

export default sidebarConfig;
