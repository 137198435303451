import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import authJwtReducer from './slices/authJwt';
import themeReducer from './slices/theme';
import checkoutReducer from './slices/checkout';
import couponReducer from './slices/coupon';
import paymentReducer from './slices/payment';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['theme']
};

const authPersistConfig = {
  key: 'authJwt',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  checkout: checkoutReducer,
  coupon: couponReducer,
  payment: paymentReducer,
  theme: themeReducer,
  authJwt: persistReducer(authPersistConfig, authJwtReducer)
});

export { rootPersistConfig, rootReducer };
