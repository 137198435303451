import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import HomeLayout from '../layouts/home';
// guards
import HomeGuard from '../guards/HomeGuard';
// paths
import { PATH_HOME } from './paths';
import { RouteItem } from '../@types/route';

// ----------------------------------------------------------------------

const HomeRoutes: RouteItem = {
  path: '*',
  layout: HomeLayout,
  guard: HomeGuard,
  routes: [
    {
      exact: true,
      path: PATH_HOME.root,
      component: lazy(() => import('../views/landingPage/LandingPage'))
    },
    {
      exact: true,
      path: PATH_HOME.plans,
      component: lazy(() => import('../views/landingPage/purchase/Pricing'))
    },
    {
      exact: true,
      path: PATH_HOME.payment,
      component: lazy(() => import('../views/landingPage/purchase/Payment'))
    },
    {
      exact: true,
      path: PATH_HOME.register,
      component: lazy(() => import('../views/landingPage/purchase/Register'))
    },
    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default HomeRoutes;
