import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_ADMIN } from './paths';
// guards
import AuthGuard from '../guards/AuthGuard';
// layouts
import DashboardLayout from '../layouts/dashboard';
import { RouteItem } from '../@types/route';
import { PERMISSIONS } from './permissions';

// ----------------------------------------------------------------------

const AdminRoutes: RouteItem = {
  path: PATH_ADMIN.root,
  guard: AuthGuard,
  permissions: PERMISSIONS.admin.default,
  layout: DashboardLayout,
  routes: [
    // ADMIN
    {
      exact: true,
      path: PATH_ADMIN.general.app,
      component: lazy(() => import('../views/admin/DashboardAdmin'))
    },

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AdminRoutes;
