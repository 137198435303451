import { Icon } from '@iconify/react';
import { Location } from 'history';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Collapse,
  InputBase,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core';
// theme
import typography from '../../theme/typography';
import { PropsItems } from '../../@types/Sidebar';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  disabledInput: () => ({
    WebkitTextFillColor: 'unset!important',
    cursor: 'pointer'
  }),
  rootInput: () => ({
    color: 'unset!important',
    cursor: 'pointer'
  })
}));

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...typography.body2,
  height: 48,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&.isActiveRoot': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: 'absolute',
      backgroundColor: theme.palette.primary.main
    }
  },
  '&.isActiveSub': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '& .subIcon:before': {
      transform: 'scale(2)',
      backgroundColor: theme.palette.primary.main
    }
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'unset'
  }
}));

const SubIconStyle = styled('span')(({ theme }) => ({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    width: 4,
    height: 4,
    content: "''",
    display: 'block',
    borderRadius: '50%',
    backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create('transform')
  }
}));
// ----------------------------------------------------------------------

interface NavItemProps extends PropsItems {
  level: number;
  info?: ReactNode;
  open?: boolean;
  children?: ReactNode;
}

export default function SidebarItem({
  level,
  title,
  href = '#',
  info,
  icon,
  isInput,
  open = false,
  children,
  onEdit,
  onActive,
  isActive,
  endAdornment
}: NavItemProps) {
  const inputRef = useRef<HTMLInputElement>();
  const { disabledInput, rootInput } = useStyles();
  const [show, setShow] = useState(open);
  const isSubItem = level > 0;
  const [active, setActive] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!active) setIsEditing(false);
  }, [active]);

  const handleShow = () => {
    setShow((_show) => !_show);
  };

  const handleEdit = () => {
    if (active) {
      setIsEditing(true);
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }
  };

  const handleChangeItem = (e?: any) => {
    e?.preventDefault();
    setIsEditing(false);
    onEdit && onEdit(inputRef.current?.value || '');
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          button
          disableGutters
          onClick={handleShow}
          className={open ? 'isActiveRoot' : ''}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText disableTypography primary={title} />
          {info}
          <Box
            component={Icon}
            icon={show ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={show}>{children}</Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      button
      // @ts-ignore
      to={href}
      onClick={() => onActive && !active && onActive(title)}
      exact={open}
      disableGutters
      component={RouterLink}
      activeClassName={isSubItem ? 'isActiveSub' : 'isActiveRoot'}
      isActive={(match: { url: string }, location: Location) => {
        if (isActive === undefined) {
          if (!match) {
            return false;
          }
          const { url } = match;
          const { pathname } = location;
          const isMatch = url === pathname;

          if (!isSubItem) {
            return !!(url.length && pathname.includes(url));
          }

          return isMatch;
        }
        setTimeout(() => setActive(isActive), 0);
        return isActive;
      }}
    >
      <ListItemIcon>
        {isSubItem ? <SubIconStyle className="subIcon" /> : icon}
      </ListItemIcon>
      {isInput ? (
        <ListItemText
          onDoubleClick={handleEdit}
          disableTypography
          primary={
            <form onSubmit={handleChangeItem}>
              <InputBase
                defaultValue={title}
                placeholder={title}
                disabled={!isEditing}
                inputProps={{ 'aria-label': 'naked' }}
                inputRef={inputRef}
                classes={{
                  disabled: disabledInput,
                  root: rootInput
                }}
                onBlur={handleChangeItem}
              />
            </form>
          }
        />
      ) : (
        <ListItemText disableTypography primary={title} />
      )}
      {endAdornment?.component}

      {info}
    </ListItemStyle>
  );
}
