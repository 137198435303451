import { forwardRef } from 'react';
// material
import {
  alpha,
  Theme,
  useTheme,
  emphasize,
  experimentalStyled as styled
} from '@material-ui/core/styles';
import { Chip, ChipProps } from '@material-ui/core';
// @types
import { ColorSchema } from '../../@types/theme';

// ----------------------------------------------------------------------

const ChipStyle = styled(Chip)(
  ({
    theme,
    styleProps
  }: {
    theme: Theme;
    styleProps: {
      color: ColorSchema;
      variant: 'filled' | 'outlined';
      clickable?: boolean;
      onDelete?: (event: any) => void;
    };
  }) => {
    const { color, variant, clickable, onDelete } = styleProps;

    // Filled
    const styleFilled = (colorSchema: ColorSchema) => ({
      color: theme.palette[colorSchema].contrastText,
      backgroundColor: theme.palette[colorSchema].main,
      '& .MuiChip-icon': { color: 'inherit' },
      '& .MuiChip-avatar': {
        color: theme.palette[colorSchema].lighter,
        backgroundColor: theme.palette[colorSchema].dark
      },
      '& .MuiChip-deleteIcon': {
        color: alpha(theme.palette[colorSchema].contrastText, 0.7),
        '&:hover, &:active': { color: theme.palette[colorSchema].contrastText }
      }
    });

    const styleFilledClickable = (colorSchema: ColorSchema) => ({
      '&:hover, &:focus': {
        backgroundColor: emphasize(
          theme.palette[colorSchema].main,
          theme.palette.action.hoverOpacity
        )
      }
    });

    const styleFilledDeletable = (colorSchema: ColorSchema) => ({
      '&:focus': {
        backgroundColor: emphasize(
          theme.palette[colorSchema].main,
          theme.palette.action.focusOpacity
        )
      }
    });

    // Outlined
    const styleOutlined = (colorSchema: ColorSchema) => ({
      color: theme.palette[colorSchema].main,
      border: `1px solid ${theme.palette[colorSchema].main}`,
      '&:focus, &.MuiChip-clickable:hover': {
        backgroundColor: alpha(
          theme.palette[colorSchema].main,
          theme.palette.action.hoverOpacity
        )
      },
      '& .MuiChip-icon': { color: 'currentColor' },
      '& .MuiChip-avatar': {
        color: theme.palette[colorSchema].lighter,
        backgroundColor: theme.palette[colorSchema].dark
      },
      '& .MuiChip-deleteIcon': {
        color: alpha(theme.palette[colorSchema].main, 0.7),
        '&:hover, &:active': { color: theme.palette[colorSchema].main }
      }
    });

    return {
      ...(variant === 'filled'
        ? {
            ...styleFilled(color),
            ...(clickable && { ...styleFilledClickable(color) }),
            ...(onDelete && { ...styleFilledDeletable(color) })
          }
        : {
            ...styleOutlined(color)
          })
    };
  }
);

// ----------------------------------------------------------------------

interface MChipProps extends Omit<ChipProps, 'color'> {
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
}

const Mchip = forwardRef<HTMLDivElement, MChipProps>(
  (
    {
      color = 'default',
      variant = 'filled',
      clickable: clickableProp,
      onDelete: onDeleteProp,
      ...other
    },
    ref
  ) => {
    const theme = useTheme();

    if (color === 'default' || color === 'primary' || color === 'secondary') {
      return (
        <Chip
          ref={ref}
          color={color}
          variant={variant}
          clickable={clickableProp}
          onDelete={onDeleteProp}
          {...other}
        />
      );
    }

    return (
      <ChipStyle
        ref={ref}
        variant={variant}
        clickable={clickableProp}
        onDelete={onDeleteProp}
        styleProps={{
          color,
          variant,
          clickable: clickableProp,
          onDelete: onDeleteProp
        }}
        theme={theme}
        {...other}
      />
    );
  }
);

export default Mchip;
