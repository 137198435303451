const formatString = (name: string) => {
  if (name == null) {
    return '';
  }

  const words = name.split(' ');
  const numberOfWords = words.length;
  const firstWordInitialLetter = name.charAt(0);
  const lastWordInitialLetter =
    numberOfWords > 1 ? words[numberOfWords - 1].charAt(0) : '';

  return String(firstWordInitialLetter + lastWordInitialLetter).toUpperCase();
};

export { formatString };
