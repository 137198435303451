import jwtDecode from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
// utils
import axios from '../../utils/axios';
// @types
import { User } from '../../@types/user';
import { mockImgAvatar, mockImgCover } from '../../utils/mockImages';

// ----------------------------------------------------------------------

type AuthJWTState = {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: User;
};

const unAuthUser: User = {
  id: 0,
  status: false,
  modalities: [],
  members: [],
  account: {
    document: '',
    id: 0,
    idAddress: 0,
    idContact: 0,
    logo: '',
    name: '',
    status: false,
    usedTestPeriod: false
  },
  login: {
    blocked: false,
    datePassword: '',
    id: 0,
    password: '',
    quantityLogin: 0,
    userName: ''
  },
  profile: {
    address: {
      city: '',
      country: '',
      district: '',
      id: 0,
      number: 0,
      state: '',
      street: '',
      zipCode: '',
      complement: '',
      references: ''
    },
    birthday: '',
    contact: {
      email: '',
      firstPhone: '',
      id: 0,
      parent: undefined,
      secondPhone: ''
    },
    document: '',
    firstName: '',
    idLanguage: 0,
    gender: '',
    id: 0,
    language: {
      code: '',
      description: '',
      id: 0
    },
    lastName: '',
    setting: {
      id: 0,
      value: ''
    },
    about: '',
    name: ''
  },
  role: {
    id: 0,
    name: '',
    permissions: [
      {
        id: 0,
        feature: 0,
        readOnly: false
      }
    ]
  }
};

const initialState: AuthJWTState = {
  isLoading: false,
  isAuthenticated: false,
  user: unAuthUser
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = unAuthUser;
    },

    reloadUser(state, action) {
      state.user = action.payload.user;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ----------------------------------------------------------------------

export function login({
  password,
  email,
  userName
}: {
  password: string;
  email?: string;
  userName?: string;
}) {
  return async () => {
    const { dispatch } = store;
    const response = await axios.post('/auth/login', {
      email,
      userName,
      password
    });
    const { token, user } = response.data;

    user.avatar = {
      name: 'avatar',
      size: 9999,
      type: 'image/png',
      preview: mockImgAvatar(1)
    };
    user.cover = mockImgCover(1);
    user.name = `${user.profile.firstName} ${user.profile.lastName}`;

    setSession(token);
    dispatch(slice.actions.loginSuccess({ user }));
  };
}

export function update({ data }: { data: User }) {
  return async () => {
    const { dispatch } = store;
    await axios.put('/user', data);
    const response = await axios.get('/user', {
      params: { filterUserLogged: true }
    });
    const user = response.data.user[0];

    user.avatar = {
      name: 'avatar',
      size: 9999,
      type: 'image/png',
      preview: mockImgAvatar(1)
    };
    user.cover = mockImgCover(1);

    dispatch(slice.actions.reloadUser({ user }));
  };
}

// ----------------------------------------------------------------------

export function register({
  email,
  password,
  firstName,
  lastName
}: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}) {
  return async () => {
    const { dispatch } = store;

    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });

    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async () => {
    const { dispatch } = store;
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get('/user', {
          params: { filterUserLogged: true }
        });
        const { user: users } = response.data;
        const user = users[0];
        user.name = `${user.profile.firstName} ${user.profile.lastName}`;

        user.avatar = {
          name: 'avatar',
          size: 9999,
          type: 'image/png',
          preview: mockImgAvatar(1)
        };
        user.cover = mockImgCover(1);

        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: unAuthUser
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
