const PermissionsEnum = Object.freeze({
  MEMBER: 1,
  ADMIN: 2
});

export const PERMISSIONS = {
  enum: PermissionsEnum,
  dashboard: {
    root: [PermissionsEnum.MEMBER]
  },
  admin: {
    default: [PermissionsEnum.ADMIN]
  },
  member: {
    default: [PermissionsEnum.MEMBER]
  }
};
