import { ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
// material
import { List, ListSubheader } from '@material-ui/core';
// types
import { Props, PropsItems } from '../../@types/Sidebar';
import { User } from '../../@types/user';
// hooks
import useAuth from '../../hooks/useAuth';
// components
import Scrollbar from '../Scrollbar';
import SidebarItem from './SidebarItem';
import { checkPermissions } from '../../utils/permissions';

// ----------------------------------------------------------------------
type ReduceChildParams = {
  array: ReactNode[];
  item: PropsItems;
  pathname: string;
  level: number;
  user: User;
};

function reduceChild({
  array,
  item,
  pathname,
  level,
  user
}: ReduceChildParams) {
  const key = level + item.title;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    return [
      ...array,
      <SidebarItem key={key} level={level} {...item} open={Boolean(match)}>
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items,
          user
        })}
      </SidebarItem>
    ];
  }
  if (
    (!!item.permissions && checkPermissions(item.permissions, user)) ||
    !item.permissions
  ) {
    return [...array, <SidebarItem key={key} level={level} {...item} />];
  }

  return [...array];
}

type RenderSidebarItemsParams = {
  items: PropsItems[];
  pathname: string;
  level?: number;
  user: User;
  className?: string;
};

function renderSidebarItems({
  items,
  pathname,
  level = 0,
  user,
  className
}: RenderSidebarItemsParams) {
  return (
    <List className={className} disablePadding>
      {items.reduce<ReactNode[]>(
        (array, item) => reduceChild({ array, item, pathname, level, user }),
        []
      )}
    </List>
  );
}

type NavBarProps = {
  items: Props[];
  className?: string;
};

export default function Sidebar({ items, className }: NavBarProps) {
  const { pathname } = useLocation();
  const { user } = useAuth();

  const renderContent = (
    <Scrollbar>
      {items.map((list, index) => (
        <div key={index}>
          {(!!list.permissions && checkPermissions(list.permissions, user)) ||
          !list.permissions ? (
            <List
              disablePadding
              key={list.subheader}
              subheader={
                list.subheader ? (
                  <ListSubheader
                    disableSticky
                    disableGutters
                    sx={{
                      mt: 3,
                      mb: 2,
                      pl: 5,
                      color: 'text.primary',
                      typography: 'overline'
                    }}
                  >
                    {list.subheader}
                  </ListSubheader>
                ) : undefined
              }
            >
              {renderSidebarItems({
                items: list.items,
                pathname,
                user,
                className
              })}
            </List>
          ) : undefined}
        </div>
      ))}
    </Scrollbar>
  );

  return <>{renderContent}</>;
}
