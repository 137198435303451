import { TextField, TextFieldProps } from '@material-ui/core';

export default function MTextField({
  required = true,
  ...others
}: TextFieldProps) {
  return (
    <TextField
      {...others}
      label={required ? `${others?.label} *` : others?.label}
    />
  );
}
