//
import {
  Avatar as MaterialAvatar,
  AvatarProps as MaterialAvatarProps,
  makeStyles
} from '@material-ui/core';
import { getColor } from '../utils/getColor';
import { formatString } from '../utils/formatString';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: (props: { color: string }) => ({
    backgroundColor: props.color,
    color: 'white'
  })
}));

export interface AvatarProps extends MaterialAvatarProps {
  name: string;
  src?: string;
}

export default function Avatar({ name, src, ...other }: AvatarProps) {
  const styles = useStyles({ color: getColor(name) });

  return (
    <MaterialAvatar src={src} alt={name} {...other} className={styles.root}>
      {formatString(name)}
    </MaterialAvatar>
  );
}
