import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
// icons
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { MIconButton } from '../components/@material-extend';

type CloseSnackbarProps = (key?: SnackbarKey) => void;
type EnqueueSnackbarProps = (
  message: SnackbarMessage,
  options?: OptionsObject
) => SnackbarKey;

type VariantProps = {
  msg: string;
  options?: OptionsObject;
};

class Snackbar {
  private snackBar = {
    enqueueSnackbar: (
      message: SnackbarMessage,
      options?: OptionsObject | undefined
    ) => {},
    closeSnackbar: (key?: SnackbarKey) => {}
  };

  setSnackbar(
    enqueueSnackbar: EnqueueSnackbarProps,
    closeSnackbar: CloseSnackbarProps
  ) {
    this.snackBar.enqueueSnackbar = enqueueSnackbar;
    this.snackBar.closeSnackbar = closeSnackbar;
  }

  success({ msg, options }: VariantProps) {
    return this.toast(msg, { ...options, variant: 'success' });
  }

  warning({ msg, options }: VariantProps) {
    return this.toast(msg, { ...options, variant: 'warning' });
  }

  info({ msg, options }: VariantProps) {
    return this.toast(msg, { ...options, variant: 'info' });
  }

  error({ msg, options }: VariantProps) {
    return this.toast(msg, { ...options, variant: 'error' });
  }

  toast(msg: string, options: any) {
    const finalOptions = {
      variant: 'default',
      action: (key: SnackbarKey) => (
        <MIconButton
          size="small"
          onClick={() => this.snackBar.closeSnackbar(key)}
        >
          <Icon icon={closeFill} />
        </MIconButton>
      ),
      ...options
    };
    return this.snackBar.enqueueSnackbar(msg, { ...finalOptions });
  }

  closeSnackbar(key: SnackbarKey) {
    this.snackBar.closeSnackbar(key);
  }
}

export default new Snackbar();
