import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function Progress(theme: Theme) {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          overflow: 'hidden'
        },
        bar: {
          borderRadius: 4
        },
        buffer: {
          backgroundColor: 'transparent'
        }
      }
    }
  };
}
