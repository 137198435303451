import { motion, AnimatePresence, AnimationControls } from 'framer-motion';
// material
import { Dialog, Box, Paper, DialogProps } from '@material-ui/core';

//
import { varFadeInUp } from './variants';
// ----------------------------------------------------------------------

interface DialogAnimateProps extends DialogProps {
  animate?: boolean | AnimationControls;
  onClose?: VoidFunction;
  persistent?: boolean;
  blockAnimation?: boolean;
  onAnimationComplete?: () => void;
}

export default function DialogAnimate({
  open = false,
  animate,
  onClose,
  children,
  persistent,
  blockAnimation,
  onAnimationComplete,
  ...other
}: DialogAnimateProps) {
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={onClose}
          disableEscapeKeyDown={persistent}
          PaperComponent={(props) => (
            <Box
              component={motion.div}
              {...(!blockAnimation ? animate || varFadeInUp : undefined)}
              onAnimationComplete={onAnimationComplete}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box
                onClick={onClose}
                sx={{ width: '100%', height: '100%', position: 'fixed' }}
              />
              <Paper {...props}>{props.children}</Paper>
            </Box>
          )}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}
