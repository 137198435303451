import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_ADMIN } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
  blockAdmin?: boolean;
};

export default function GuestGuard({ children, blockAdmin }: GuestGuardProps) {
  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    if (!blockAdmin) return <Redirect to={PATH_DASHBOARD.root} />;

    return <Redirect to={PATH_ADMIN.root} />;
  }

  return <>{children}</>;
}
