import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { Coupon, CouponState, CouponFilters } from '../../@types/coupon';
import { get } from '../../services/api/coupon';

// ----------------------------------------------------------------------

const initialState: CouponState = {
  isLoading: false,
  error: false,
  coupons: [] as Array<Coupon>,
  coupon: {} as Coupon,
  filters: {
    id: 0,
    name: '',
    value: 0,
    active: false,
    isExpirable: false,
    description: '',
    createdAt: '',
    expiredAt: ''
  }
};

const slice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET COUPON
    getCouponsSuccess(state, action: { payload: Array<Coupon> }) {
      state.isLoading = false;
      state.coupons = action.payload
        .sort((coupon) => (coupon.active ? -1 : 0))
        .sort((coupon) => (coupon.name === state.coupon.name ? -1 : 0));
    },

    getCouponSuccess(state, action) {
      state.isLoading = false;
      state.coupon = action.payload;
    },

    clearCoupons(state) {
      state.coupon = {} as Coupon;
      state.coupons = [] as Array<Coupon>;
    },

    // filters
    filterCoupons(
      state,
      action: {
        payload: {
          filter: CouponFilters;
        };
      }
    ) {
      const hasFilters =
        JSON.stringify(state.filters) !== JSON.stringify(action.payload.filter);

      if (hasFilters) state.filters = action.payload.filter;
    }
  }
});

// Reducer
export default slice.reducer;

export const { clearCoupons } = slice.actions;

// ----------------------------------------------------------------------

export function getCoupons(filters?: CouponFilters) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const coupons = await get(filters);

      dispatch(slice.actions.getCouponsSuccess(coupons));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCoupon(valueID: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const coupons = await get({ id: valueID });

      dispatch(slice.actions.getCouponSuccess(coupons[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
