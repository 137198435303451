import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_AUTH, PATH_DASHBOARD, PATH_ADMIN } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';
import { checkPermissions } from '../utils/permissions';
import { PERMISSIONS } from '../routes/permissions';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
  permissions?: number[];
};

export default function AuthGuard({ children, permissions }: AuthGuardProps) {
  const { isLoading, isAuthenticated, user } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Redirect to={PATH_AUTH.login} />;
  }

  if (permissions && !checkPermissions(permissions, user)) {
    if (
      user.role.permissions?.some(
        (role) => role.feature === PERMISSIONS.enum.ADMIN
      )
    )
      return <Redirect to={PATH_ADMIN.general.app} />;

    return <Redirect to={PATH_DASHBOARD.general.app} />;
  }

  return <>{children}</>;
}
