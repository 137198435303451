/* eslint-disable prefer-destructuring */
import { createSlice } from '@reduxjs/toolkit';
import { Plan } from '../../@types/plan';
import {
  Member,
  MemberState,
  Checkout,
  CheckoutPlan
} from '../../@types/member';
import { PlanPeriod } from '../../@types/planPeriod';

const initialState: MemberState = {
  isLoading: false,
  error: false,
  members: [],
  hasMore: false,
  length: 0,
  totalRecords: 0,
  page: 1,
  memberPerPage: 6,
  latestMembers: [],
  notifications: null,
  checkout: {
    member: {} as Member,
    plan: {} as CheckoutPlan,
    teachers: [],
    activeStep: 0,
    subtotal: 0,
    total: 0,
    discount: 0
  } as Checkout
};

const slice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addCheckout(state, action) {
      const member: Member = action.payload[0];
      member.profile.gender = member.profile.gender.charAt(0);
      state.checkout.member = member;
    },

    addPlan(state, action: { payload: Plan }) {
      state.checkout.plan = {
        ...action.payload,
        selectedPeriod: action.payload.planPeriod[0]
      };
      setPlanPeriod(action.payload.planPeriod[0]);

      state.checkout.member.idPlan = action.payload.id;
    },

    setPlanPeriod(state, action: { payload: PlanPeriod }) {
      state.checkout.plan = {
        ...state.checkout.plan,
        selectedPeriod: action.payload
      };
      state.checkout.member.idPlanPeriod = action.payload.id;
      state.checkout.subtotal = state.checkout.plan.selectedPeriod.price;
      const total =
        state.checkout.plan.selectedPeriod.price - state.checkout.discount;
      state.checkout.total = total < 0 ? 0 : total;
    },

    addTeachers(state, action) {
      state.checkout.teachers = action.payload;
    },

    getCheckout(state, action) {
      const member = action.payload;
      state.checkout.member = member;
    },

    resetCheckout(state) {
      state.checkout.activeStep = 0;
      state.checkout.member = {} as Member;
      state.checkout.plan = {} as CheckoutPlan;
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      localStorage.removeItem('member');
      state.isLoading = false;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      const total = state.checkout.plan.selectedPeriod.price - discount;
      state.checkout.total = total < 0 ? 0 : total;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  getCheckout,
  onGotoStep,
  onNextStep,
  onBackStep,
  resetCheckout,
  addCheckout,
  addPlan,
  applyDiscount,
  addTeachers,
  setPlanPeriod
} = slice.actions;
