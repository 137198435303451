import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_DASHBOARD } from './paths';
// guards
import AuthGuard from '../guards/AuthGuard';
// layouts
import DashboardLayout from '../layouts/dashboard';
import { RouteItem } from '../@types/route';
import { PERMISSIONS } from './permissions';

// ----------------------------------------------------------------------

const DashboardRoutes: RouteItem = {
  path: PATH_DASHBOARD.root,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.general.app,
      permissions: PERMISSIONS.dashboard.root,
      component: lazy(() => import('../views/GeneralApp'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: () => <Redirect to={PATH_DASHBOARD.general.app} />
    },

    // MANAGEMENT : USER
    {
      exact: true,
      path: PATH_DASHBOARD.user.profile,
      component: lazy(() => import('../views/UserProfile'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.user.account,
      component: lazy(() => import('../views/UserAccount'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.user.root,
      component: () => <Redirect to={PATH_DASHBOARD.user.profile} />
    },

    // MANAGEMENT : MEMBER
    {
      exact: true,
      path: PATH_DASHBOARD.member.cards,
      permissions: PERMISSIONS.member.default,
      component: lazy(() => import('../views/member/MemberCards'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.member.checkout,
      component: lazy(() => import('../views/member/checkout/Checkout'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.member.register,
      component: lazy(() => import('../views/member/NewMember'))
    },

    // MANAGEMENT : USER
    {
      exact: true,
      path: PATH_DASHBOARD.user.cards,
      permissions: PERMISSIONS.member.default,
      component: lazy(() => import('../views/user/UserCards'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.user.register,
      permissions: PERMISSIONS.member.default,
      component: lazy(() => import('../views/user/NewUser'))
    },

    // MANAGEMENT : PLAN
    {
      exact: true,
      path: PATH_DASHBOARD.plan.root,
      permissions: PERMISSIONS.member.default,
      component: lazy(() => import('../views/plan/PlanList'))
    },

    // MANAGEMENT : PERMISSION
    {
      exact: true,
      path: PATH_DASHBOARD.permission.root,
      permissions: PERMISSIONS.member.default,
      component: lazy(() => import('../views/permission/Permission'))
    },

    // NOT FOUND
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default DashboardRoutes;
