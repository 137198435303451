const getColor = (str: string) => {
  const lightness = 60;
  const saturation = 40;

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};

export { getColor };
