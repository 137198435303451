import { User } from '../@types/user';

export const checkPermissions = (values: number[] | undefined, user: User) => {
  if (!values) return true;

  const perms =
    user.role.permissions
      ?.map((perm) => {
        if (values.some((val) => val === perm.feature)) return perm;

        return undefined;
      })
      .filter((item) => item) || [];

  return perms.length === values.length;
};
