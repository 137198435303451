import { OutlinedInput, OutlinedInputProps } from '@material-ui/core';

export default function MOutlinedInput({
  required = true,
  ...others
}: OutlinedInputProps) {
  return (
    <OutlinedInput
      {...others}
      placeholder={required ? `${others?.placeholder} *` : others?.placeholder}
    />
  );
}
