import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import brLocale from 'date-fns/locale/pt-BR';
// material
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
// redux
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { store, persistor } from './redux/store';
// routes
import routes, { renderRoutes } from './routes';
// theme
import ThemeConfig from './theme';
// services
import { queryClient } from './services/queryClient';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import JwtProvider from './components/authentication/JwtProvider';
import Snackbar from './utils/Snackbar';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

export default function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <PersistGate loading={<LoadingScreen />} persistor={persistor}>
            <ThemeConfig>
              <RtlLayout>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={brLocale}
                >
                  <NotistackProvider>
                    <Router history={history}>
                      <JwtProvider>
                        <CreateSnackbar />
                        {renderRoutes(routes)}
                        <ScrollToTop />
                        <GoogleAnalytics />
                      </JwtProvider>
                    </Router>
                  </NotistackProvider>
                </LocalizationProvider>
              </RtlLayout>
            </ThemeConfig>
          </PersistGate>
        </ReduxProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </HelmetProvider>
  );
}

function CreateSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    Snackbar.setSnackbar(enqueueSnackbar, closeSnackbar);
  }, [enqueueSnackbar, closeSnackbar]);

  return <></>;
}
