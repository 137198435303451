// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
// components
import Avatar, { AvatarProps } from './Avatar';

export default function MyAvatar({
  ...other
}: Omit<AvatarProps, 'name' | 'src'>) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user.profile.avatar}
      name={`${user.profile.firstName} ${user.profile.lastName}`}
      sx={{
        fontSize: 14
      }}
      {...other}
    >
      {createAvatar(`${user.profile.firstName} ${user.profile.lastName}`).name}
    </Avatar>
  );
}
