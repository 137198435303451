import axios from 'axios';

// ----------------------------------------------------------------------

const inDevelopment = process.env.NODE_ENV === 'development';

const axiosInstance = axios.create({
  baseURL: inDevelopment
    ? 'http://localhost:22786/api'
    : process.env.REACT_APP_CORE_URL
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    let message = 'Something went wrong';
    try {
      if (error.response.status === 401) {
        window.location.href = '/auth/login';
      }
    } catch {
      console.log();
    }
    if (error.response) {
      if (
        typeof error.response.data === 'string' ||
        error.response.data instanceof String
      ) {
        message = error.response.data.toString();
      }
    }
    return Promise.reject(message);
  }
);

export default axiosInstance;
